import React from 'react';
import styled from 'styled-components';
import { Section } from '@/features/new-landing-page/components/section';
import { StrapiTextual } from '@/features/strapi/components/textual';
import { PricingV2ToolsYouNeedToScale } from '@/features/new-landing-page/pages/pricing-v2-content';
import { StrapiImageTextual } from '@/features/strapi/components/image-textual';
import { VSpace } from '@/components/spacing';
import { breakpoints } from '@/components/responsive';

export function ToolsYouNeedToScale() {
  return (
    <Section>
      <StrapiTextual {...PricingV2ToolsYouNeedToScale.title} />
      <VSpace className="only-desktop" value={3} />
      <VSpace className="only-mobile" value={1} />
      <Grid>
        {PricingV2ToolsYouNeedToScale.features.map((feature) => (
          <StrapiImageTextual key={feature?.textual?.text} {...feature} />
        ))}
      </Grid>
    </Section>
  );
}

const Grid = styled.div`
  margin-left: 6vw;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 1.8rem;
  column-gap: 4rem;

  ${breakpoints.down(`md`)} {
    margin-left: -5vw;
    justify-items: center;
    grid-template-columns: 1fr;
    row-gap: 3rem;
  }
`;
