import React from 'react';
import { PricingV2 } from '@/features/new-landing-page/pages/pricing-v2';

export default PricingV2;

export const Head = () => (
  <script
    charSet="utf-8"
    type="text/javascript"
    src="//js-eu1.hsforms.net/forms/embed/v2.js"
  />
);
